import "./App.css";
import { Particles } from "react-particles-js";
import particlesConfig from "./particlesjs-config.json";
import styles from "./styleConfig.json";
import { Scrollbars } from "react-custom-scrollbars";
import React, { useRef, useState } from "react";
import { MainText } from "./components/text";
import {
  Button,
  HorizontalFlexContainer,
  Spacer,
  VerticalFlexContainer,
} from "./components/layout";
import axios from "axios";

function App() {
  const scrollRef = useRef(null);
  const [tokenAddress, setTokenAddress] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [enteringAddress, setEnteringAddress] = useState(true);
  const [viewingResults, setViewingResults] = useState(false);
  const [widgetData, setWdigetData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const submitAddress = () => {
    setSubmitting(true);
    setEnteringAddress(false);

    const getData = async () => {
      const result = await axios.get(`${apiBaseUrl}/token/${tokenAddress}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status == 404 || status == 400,
      });
      if (result.data.success) {
        setWdigetData(result.data.data);
        setViewingResults(true);
      } else {
        setEnteringAddress(true);
      }
      setSubmitting(false);
    };

    getData();
  };

  // const testData = [
  //   {
  //     name: "Reflection",
  //     description: "You and the liquidity pools will receive value on sells",
  //     risk: "L",
  //   },
  //   {
  //     name: "Liquidity Holders",
  //     description: "The distribution and ownership of the liquidity pool",
  //     risk: "M",
  //   },
  //   {
  //     name: "Reflection",
  //     description: "You and the liquidity pools will receive value on sells",
  //     risk: "H",
  //   },
  //   {
  //     name: "Liquidity Holders",
  //     description: "The distribution and ownership of the liquidity pool",
  //     // risk: "L",
  //   },
  // ];

  return (
    <div className="App">
      <Scrollbars
        ref={scrollRef}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbMinSize={30}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            backgroundColor: styles.background.secondaryColor,
          }}
        >
          <Particles height="100vh" width="100%" params={particlesConfig} />
        </div>
        <div
          style={{
            height: "100vh",
            position: "relative",
          }}
        >
          <div style={{ zIndex: 0, height: "100%", width: "100%" }}>
            <VerticalFlexContainer
              width="100%"
              height="100%"
              align={"center"}
              justify="space-around"
            >
              <MainText color={styles.textColor.light} size={90}>
                Ragnarok
              </MainText>
              <div
                style={{
                  borderRadius: 32,
                  backgroundColor: styles.background.tertiaryColor,
                  padding: 32,
                }}
              >
                {submitting ? (
                  <Submitting />
                ) : viewingResults ? (
                  <Results
                    data={widgetData}
                    goBack={() => {
                      setEnteringAddress(true);
                      setViewingResults(false);
                    }}
                  />
                ) : (
                  <EnterAddress
                    tokenAddress={tokenAddress}
                    setTokenAddress={setTokenAddress}
                    onSubmit={submitAddress}
                  />
                )}
              </div>
              <Spacer height={1} />
            </VerticalFlexContainer>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

function EnterAddress({ setTokenAddress, tokenAddress, onSubmit }) {
  return (
    <VerticalFlexContainer align="center" justify="space-around">
      <MainText color={styles.textColor.light}>
        Enter contract address below to begin scan:
      </MainText>
      <Spacer height={16} />
      <input
        type="text"
        placeholder="Enter token/contract address"
        defaultValue={tokenAddress}
        onChange={(e) => setTokenAddress(e.target.value)}
      />
      <Spacer height={16} />
      <Button onClick={() => onSubmit()}>
        <MainText color={styles.textColor.light}>Submit</MainText>
      </Button>
    </VerticalFlexContainer>
  );
}

function Submitting() {
  return (
    <VerticalFlexContainer align="center" justify="center">
      <MainText color={styles.textColor.light}>Loading</MainText>
    </VerticalFlexContainer>
  );
}

function Results({ data, goBack }) {
  return (
    <VerticalFlexContainer align="center" justify="center">
      <MainText color={styles.textColor.light}>Results</MainText>
      <Spacer height={16} />
      {data?.map((item) => (
        <Widget item={item} />
      ))}
      <Spacer height={16} />
      <Button onClick={() => goBack()}>
        <MainText color={styles.textColor.light}>Go Back</MainText>
      </Button>
    </VerticalFlexContainer>
  );
}

function Widget({ item }) {
  let riskColor;
  switch (item.risk) {
    case "L":
      riskColor = "#7fa65a";
      break;
    case "M":
      riskColor = "#de984e";
      break;
    case "H":
      riskColor = "#de3428";
      break;
    default:
      riskColor = "#222222";
      break;
  }
  return (
    <HorizontalFlexContainer
      align="center"
      justify="space-between"
      width="100%"
    >
      <div
        style={{
          width: "100%",
          borderRadius: 16,
          border: "1px solid #222222",
          padding: 16,
          margin: 8,
          backgroundColor: "rgba(50, 50, 50, 0.3)",
          display: "flex",
        }}
      >
        <VerticalFlexContainer justify="space-around" flex={1}>
          <MainText color={styles.textColor.light} size={36}>
            {item.name}
          </MainText>
          <MainText color={styles.textColor.light}>{item.description}</MainText>
        </VerticalFlexContainer>
        <div style={{ padding: 8 }}>
          <div
            style={{
              height: "60px",
              borderRadius: "50%",
              width: "60px",
              backgroundColor: riskColor,
            }}
          />
        </div>
      </div>
    </HorizontalFlexContainer>
  );
}

export default App;
